@import "styles/custom";

html {
  font-size: 14px;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-bg: #{$gray-100};
  --bs-blue-100: #{$blue-100};
  --bs-blue-300: #{$blue-300};
  --bs-blue-500: #{$blue-500};
  --v-light-blue: #e7f1ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer!important;
}

.firebase-emulator-warning {
  display: none;
}
