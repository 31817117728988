@import "styles/core";

.Loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top:0;
  left:0;
  background: $gray-100;

  &:not(.inline) {
    position: absolute;
  }
}
