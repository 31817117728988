@import "styles/core";

.Signin {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $gray-100;

  .form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    :global(.form-floating:focus-within) {
      z-index: 2;
    }

    input[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input[type="password"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
