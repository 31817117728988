@import 'styles/core';

.DataTable {

  .row {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $gray-500;

    .badge {
      position: absolute;
      font-weight: bold;
      min-width: 1.5rem;
      text-align: center;
      top: -50%;
      left: -10px;
      transform: translate(0, -50%);
      color: white;
      font-size: .75rem;
      background-color: $gray-500;
      border-radius: 10px;
      padding: 1px 3px;
      cursor: pointer;
    }
  }

  .filtered {
    position: relative;
    opacity: .3;
    --bs-table-accent-bg: transparent !important;
    transition: opacity .2s;

    &:hover {
      opacity: .6;
    }

    .flag {
      cursor: pointer;
      position: absolute;
      width: 0.625rem;
      height: calc(100% + 2px);
      background-color: $gray-600;
      top: -1px;
      right: 100%;

      &.start {
        border-radius: .5rem 0 0 0;
      }
      &.end {
        border-radius: 0 0 0 .5rem;
      }
      &.start.end {
        border-radius: .5rem 0 0 .5rem;
      }
    }
  }

}
